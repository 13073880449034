.left-arrow {
    width: 40px;
    animation: moveImage 1s ease infinite;
}

@keyframes moveImage {
    0%,
    100% {
        transform: translateX(20px);
    }
    50% {
        transform: translateX(0px);
    }
}
